import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VatTuDinhMucTable = _resolveComponent("VatTuDinhMucTable")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_button_custom = _resolveComponent("button-custom")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VatTuDinhMucTable, {
      ref: "vatTuDinhMucTableRef",
      items: _ctx.selectingDinhMuc?.vat_tu ? _ctx.selectingDinhMuc.vat_tu : [],
      onOnClose: _ctx.onCloseModal
    }, null, 8, ["items", "onOnClose"]),
    _createVNode(_component_PageRowLayout, null, {
      "page-row-header": _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.dinhMucTotalDisplay), 1)
      ]),
      "page-row-content": _withCtx(() => [
        _createVNode(_component_el_table, {
          size: "small",
          "empty-text": "Chưa có vật tư nào trong định mức",
          "cell-style": {padding: '0', height: '15px'},
          border: true,
          data: _ctx.dinhMucCoDinhItems
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              label: "ID",
              width: "60"
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(scope.row.id), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, { label: "Cấp sửa chữa" }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(scope.row.cap_sua_chua?.ten_cap_sc || scope.row.id_cap_sc), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, { label: "Mô tả" }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(scope.row.mo_ta || '-'), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, { width: "120" }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_button_custom, {
                    "button-type": _ctx.ButtonsType.INFO,
                    "color-type": _ctx.ButtonTypeColors.INFO,
                    onClick: ($event: any) => (_ctx.onDetailButtonClicked(scope.$index))
                  }, {
                    "button-label": _withCtx(() => [
                      _createTextVNode(_toDisplayString(scope.row.vat_tu.length) + " vật tư ", 1)
                    ]),
                    _: 2
                  }, 1032, ["button-type", "color-type", "onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"])
      ]),
      _: 1
    })
  ], 64))
}