
import { debounce } from 'lodash';
import {defineComponent, PropType} from 'vue';
import {IToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import { useLoading } from '@/core/hooks/use-loading';
import {usePagination} from "@/core/hooks/use-pagination";
import {getErrorMsg, swalNotification} from "@/core/helpers/utils";
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import DanhSachToaXeTable from '@/views/crafted/pages/toa-xe/danh-sach-toa-xe-table/DanhSachToaXeTable.vue';
import PageRowLayout from "@/layout/_shared/PageRowLayout.vue";
import ToaXeService from '@/core/services/ToaXe.service';
import PerPageSelection from "@/components/shared/per-page-selection/PerPageSelection.vue";
import Pagination from "@/components/shared/pagination/Pagination.vue";

export default defineComponent({
	name: 'toa-xe-cua-danh-muc',

	emits: ['open-add-toa-xe-items-modal'],

	components: {
    ButtonCustom,
    DanhSachToaXeTable,
    PageRowLayout,
    Pagination,
    PerPageSelection,
  },

	setup() {
		const { ButtonsType } = useButtonCustom();

    const { currentPage, totalItems, perPage, perPageArr, loading, searchTerm } = usePagination();

    const { startLoading, endLoading } = useLoading();

		return {
			ButtonsType,
      currentPage,
      endLoading,
      loading,
      perPage,
      perPageArr,
      searchTerm,
      startLoading,
      totalItems,
		};
	},

	props: {
    danhMucToaXeId: {
      type: Number as PropType<number>,
      required: true,
    },
	},

  watch: {
    currentPage() {
      this.fetchToaXeItems();
    },
    perPage() {
      this.fetchToaXeItems();
    },
  },

  data() {
    return {
      toaXeItems: [] as IToaXeResponseBase[],
    };
  },

  async mounted() {
    await this.fetchToaXeItems();
  },

  methods: {
		openAddToaXeItemsModal() {
			this.$emit('open-add-toa-xe-items-modal');
		},
    async fetchToaXeItems() {
      await this.startLoading();

      try {
        const { data: { data: { data, total } } } = await ToaXeService.list(
          this.currentPage,
          this.perPage,
          this.danhMucToaXeId,
          this.searchTerm,
        );

        this.totalItems = total;

        this.toaXeItems = data;

        await this.endLoading();
      } catch (error) {
        await this.endLoading();

        await swalNotification(
          getErrorMsg(error, 'Có lỗi xảy ra, không thể lấy thông tin toa xe'),
          'error'
        );
      }
    },
    search: debounce(function () {
      this.fetchToaXeItems();
    }, 500),
	},

	computed: {
		totalToaXeDisplay(): string {
			if (!this.totalItems) {
				return 'Toa xe'
			}

			return `Toa xe (${this.totalItems} toa)`;
		}
	}
})
