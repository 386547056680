
import {defineComponent, ref, computed} from 'vue';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {IDanhMucToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {useDateTime} from '@/core/hooks/use-date-time-hook';
import {getErrorMsg, swalNotification} from '@/core/helpers/utils';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import DanhMucToaXeService from '@/core/services/DanhMucToaXe.service';
import DanhSachToaXe from '@/views/crafted/pages/danh-muc-toa-xe/danh-sach-toa-xe-cua-danh-muc/DanhSachToaXe.vue';
import ModalThemToaXe from '@/views/crafted/pages/danh-muc-toa-xe/modal-them-toa-xe/ModalThemToaXe.vue';
import DinhMucCoDinhSection
	from '@/views/crafted/pages/danh-muc-toa-xe/dinh-muc-co-dinh-section/DinhMucCoDinhSection.vue';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';

export default defineComponent({
	name: 'chi-tiet-danh-muc-toa-xe',

	components: {
		ButtonCustom,
		ConfirmationDialog,
		DanhSachToaXe,
		DinhMucCoDinhSection,
		ModalThemToaXe,
		PageRowLayout,
	},

	data() {
		return {
			isModalOpen: false,
			isDeletingModalDisplay: false,
		}
	},

	setup() {
		const {goBack, push, replace, id} = useRouterCustom();

		const {moment} = useDateTime();

		const { ButtonTypeColors, ButtonsType } = useButtonCustom();

    const danhSachToaXeRef = ref();

		const danhMucToaXe = ref<IDanhMucToaXeResponseBase | null>(null);

		const isPhanCapCuoiCung = computed(() => {
			if (danhMucToaXe.value) {
				return danhMucToaXe.value.phan_cap === 1;
			}
			return false;
		});

		return {
			danhMucToaXeId: id,
      danhSachToaXeRef,
			isPhanCapCuoiCung,
			danhMucToaXe,
			goBack,
			push,
			replace,
			moment,
			ButtonTypeColors, ButtonsType,
		}
	},

	async mounted() {
		setCurrentPageTitle("Danh mục toa xe");
		await this.fetchData();
	},

	methods: {
		async fetchData() {
			try {
				const { data: { data: res } } = await DanhMucToaXeService.get(this.danhMucToaXeId);
				this.danhMucToaXe = res;
			} catch (err) {
				await swalNotification(
					'Có lỗi xảy ra khi lấy thông tin danh mục toa xe',
					'error',
				);
			}
		},

		openModal() {
			this.isModalOpen = true;
		},

		async onAddedToaXeItems() {
			this.isModalOpen = false;

			await this.fetchData();

      this.danhSachToaXeRef.currentPage = 1;

      await this.danhSachToaXeRef.fetchToaXeItems();
		},

		async confirmDelete() {
			this.isDeletingModalDisplay = false;
			try {
				await DanhMucToaXeService.delete(this.danhMucToaXeId);
				await swalNotification(
					'Xóa thành công',
					'success'
				);
				await this.replace('/quan-ly/danh-muc-toa-xe/danh-sach');
			} catch (error) {
				await swalNotification(
					getErrorMsg(error, 'Có lỗi xảy ra, không thể xóa danh mục toa xe này'),
					'error'
				);
			}
		}
	}
})
