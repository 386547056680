
import { defineComponent, PropType } from 'vue';
import { IVatTuDinhMucCoDinh } from '@/core/interfaces/ApiResponses';
import { formatCurrencyAndQuantity } from '@/core/helpers/utils';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import WordBreakColumn from '../../../../../../components/shared/work-break-column/WordBreakColumn.vue';
import ButtonCustom from '../../../../../../components/shared/buttons/ButtonCustom.vue';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import useVatTu from '@/core/hooks/use-vat-tu';

export default defineComponent({
  name: 'vat-tu-dinh-muc-table',

  emits: ['on-close'],

  components: {
    ButtonCustom,
    WordBreakColumn,
  },

  setup() {
    const { push } = useRouterCustom();

    const { ButtonTypeColors, ButtonsType } = useButtonCustom();

    const { isTextDangerColumn, isValidLabel } = useVatTu();

    return {
      ButtonTypeColors, ButtonsType,
      push,
      isTextDangerColumn, isValidLabel,
    }
  },

  props: {
    items: {
      type: Array as PropType<Array<IVatTuDinhMucCoDinh>>,
    },
  },

  data() {
    return {
      dataItems: this.items as IVatTuDinhMucCoDinh[],
      isDialogDisplay: false,
    }
  },

  watch: {
    items(value) {
      this.dataItems = value;
    },
  },

  methods: {
    formatCurrencyAndQuantity,
  }
})
