
import {defineComponent, PropType, ref} from 'vue';
import {IDinhMucCoDinhNewResponseBase} from '@/core/interfaces/ApiResponses';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import VatTuDinhMucTable from './vat-tu-dinh-muc-table/VatTuDinhMucTable.vue';

export default defineComponent({
	name: 'dinh-muc-co-dinh-section',

	components: {
		PageRowLayout,
		ButtonCustom,
		VatTuDinhMucTable,
	},

	emits: ['added'],

	setup() {
		const { ButtonsType, ButtonTypeColors } = useButtonCustom();
		const vatTuDinhMucTableRef = ref();

		return {
			ButtonsType, ButtonTypeColors,
			vatTuDinhMucTableRef,
		}
	},

	props: {
		danhMucToaXeId: {
			type: Number,
			required: true,
		},
		dinhMucCoDinhItems: {
			type: Array as PropType<Array<IDinhMucCoDinhNewResponseBase>>,
			default: () => ([]),
		}
	},

	data() {
		return {
			isModalOpen: false,
			selectingDinhMuc: null as IDinhMucCoDinhNewResponseBase | null,
		}
	},

	computed: {
		dinhMucTotalDisplay() {
			if (!this.dinhMucCoDinhItems) return `Định mức cố định (chưa có vật tư)`;
			return `Định mức cố định (${this.dinhMucCoDinhItems.length} định mức)`;
		}
	},

	methods: {
		onDetailButtonClicked(index) {
			this.selectingDinhMuc = this.dinhMucCoDinhItems[index]
			if (this.vatTuDinhMucTableRef) {
        this.vatTuDinhMucTableRef.isDialogDisplay = true;
      }
		},

		onCloseModal() {
			this.selectingDinhMuc = null;
			if (this.vatTuDinhMucTableRef) {
        this.vatTuDinhMucTableRef.isDialogDisplay = false;
      }
		},
	}
})
