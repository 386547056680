
import {defineComponent, PropType, ref} from 'vue';
import {debounce} from 'lodash';
import {IToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import {swalNotification} from '@/core/helpers/utils';
import {useButtonCustom} from '@/core/hooks/use-button-custom';
import {usePagination} from '@/core/hooks/use-pagination';

import ToaXeService from '@/core/services/ToaXe.service';
import Pagination from '@/components/shared/pagination/Pagination.vue';
import DanhMucToaXeService from '@/core/services/DanhMucToaXe.service';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';

export default defineComponent({
	name: 'modal-them-toa-xe',

	components: {ButtonCustom, Pagination },

	emits: ['close', 'added'],

	props: {
		isModalOpen: {
			type: Boolean,
			default: false,
		},
		selectedToaXeItems: {
			type: Array as PropType<Array<IToaXeResponseBase>>,
      required: true,
		},
		danhMucToaXeId: {
			type: Number,
			required: true,
		}
	},

	data() {
		return {
			isOpen: false,
      searchTerm: '',
			selectedToaXeIds: [] as number[],
			selectedToaXeIdArr: [] as boolean[],
		}
	},

	setup() {
		const toaXeItems = ref<IToaXeResponseBase[]>([]);

    const { ButtonsType, ButtonTypeColors } = useButtonCustom();

		const { currentPage, indexCal, totalItems, perPage, perPageArr } = usePagination();

		return {
      ButtonsType,
      ButtonTypeColors,
			currentPage,
      indexCal,
			perPage,
			perPageArr,
			toaXeItems,
			totalItems,
		}
	},

	watch: {
		isModalOpen: {
			async handler(newValue) {
				if (newValue) {
					this.isOpen = newValue;

					this.currentPage = 1;

          this.searchTerm = '';

					await this.fetchToaXeItems(this.currentPage);

					if (this.selectedToaXeItems.length) {
						this.selectedToaXeIds = this.selectedToaXeItems.map((item) => item.id);

						this.selectedToaXeIdArr = this.toaXeItems.map(item => {
							return this.selectedToaXeIds.includes(item.id);
						});
					}
				}
			},
			deep: true,
		},

		async currentPage(newValue) {
			await this.fetchToaXeItems(newValue);

			if (this.selectedToaXeItems) {
				this.selectedToaXeIdArr = this.toaXeItems.map(item => {
					return this.selectedToaXeIds.includes(item.id);
				});
			}
		}
	},

	computed: {
		totalToaXeDangChonTrongPage() {
			let count = 0;
			this.selectedToaXeIdArr.forEach(item => {
				if (item) count += 1;
			});
			return count;
		},

		totalToaXeDangChon() {
			return this.selectedToaXeIds.length;
		},

		titleDisplay() {
			return `Danh sách toa xe (đã chọn ${this.totalToaXeDangChon}, trong trang này ${this.totalToaXeDangChonTrongPage})`;
		}
	},

	methods: {
		onRowClick(row) {
			const index = this.toaXeItems.findIndex(toaXe => toaXe.id === row.id);
			this.onToaXeSelected(!this.selectedToaXeIdArr[index], row.id, index)
		},
		async fetchToaXeItems(page) {
			try {
				const { data: { data: { data: items, total: totalItem } } } = await ToaXeService.list(
					page,
					this.perPage,
          0,
          this.searchTerm,
				);

				this.toaXeItems = items;
				this.totalItems = totalItem;
			} catch {
				await swalNotification(
					'Có lỗi xảy ra khi lấy danh sách toa xe',
					'error',
				)
			}
		},
		onToaXeSelected(value, toaXeId, index) {
			this.selectedToaXeIdArr[index] = value;

			const foundIndex = this.selectedToaXeIds.findIndex(id => id === toaXeId);

			if (foundIndex >= 0) {
				this.selectedToaXeIds.splice(foundIndex, 1);
			} else this.selectedToaXeIds.push(toaXeId);
		},
		onClose() {
			this.isOpen = false;
			this.$emit('close');
		},
    search: debounce(function() {
      this.currentPage = 1;

      this.fetchToaXeItems(this.currentPage);
    }, 500),
		async submit() {
			try {
				await DanhMucToaXeService.assignToaXeItems(
					this.danhMucToaXeId,
					this.selectedToaXeIds
				);
				this.isOpen = false;
				this.$emit('added');
			} catch {
				await swalNotification(
					'Có lỗi xảy ra khi thêm toa xe',
					'error',
				);
				this.onClose();
			}
		}
	}
})
